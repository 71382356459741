<template>
  <a-row class="wrapper" type="flex" :gutter="16">
    <a-col :span="6">
      <div class="antd-panel">
        <div class="tool-tip">
          <a-button class="ant-btn ant-btn-primary" @click="createWikiDicEvt()" v-permit:manageWiki="{moduleId:17}">+ 新建目录
          </a-button>
          <a-input-search placeholder="请输入" @change="onSearch" />
        </div>
        <!-- 新增目录弹窗 -->
        <add-form v-if="addFormVisible" ref="addForm" @refreshDataList="loadTreeData"></add-form>
        <!-- 修改目录弹窗 -->
        <update-form v-if="updateFormVisible" ref="updateForm" @refreshDataList="loadTreeData"></update-form>
        <!-- 附件管理弹窗 -->
        <accessory-form v-if="accessoryVisible" ref="accessoryForm" @refreshDataList="$refs.table.refresh(true)"></accessory-form>

        <a-tree :show-line="showLine" :tree-data="treeData" :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent" @expand="onExpand">
          <template slot="custom" slot-scope="item">
            <span @mouseover="handleOver(item)" @mouseout="handleOut(item)">
              <span v-if="item.title.indexOf(searchValue) > -1" @click="onSelectNode(item)">
                {{item.title.substr(0, item.title.indexOf(searchValue)) }}
                <span style="color: #f50">{{searchValue}}</span>
                {{item.title.substr(item.title.indexOf(searchValue) + searchValue.length)}}
              </span>
              <span v-else @click="onSelectNode(item)">{{item.title}}</span>
              <span v-show="item.isShow" class="tree-node-handle" v-permit:manageWiki="{moduleId:17}">
                <a-tooltip placement="bottom" title="新增子菜单">
                  <a-icon type="plus-circle-o" @click="createSubMenuEvt(item)" />
                </a-tooltip>
                <a-tooltip placement="bottom" title="修改菜单">
                  <a-icon type="edit" @click="updateWikiDicEvt(item)" />
                </a-tooltip>
                <a-tooltip placement="bottom" title="删除菜单">
                  <a-icon type="minus-circle-o" @click="deleteWikiDicEvt(item)" />
                </a-tooltip>
              </span>
            </span>
          </template>
        </a-tree>
      </div>
    </a-col>
    <a-col :span="18">
      <div class="antd-panel" style="position:relative;">
        <a-button style="position: absolute; top:20px;right:10px;" type="primary" @click="$router.back()">返回</a-button>
        <a-button type="primary" style="position:absolute;right:190px;" v-if="updateEvt" @click="updateWikiDicContent()" v-permit:manageWiki="{moduleId:17}">编辑
        </a-button>
        <a-button type="primary" @click="fileManagementButton()" style="position:absolute;right:80px;" v-if="updateEvt">附件列表({{fileSize}})</a-button>
        <div v-show="!isUpdate">
          <a-empty v-if="wikiDic.content==null || wikiDic.content==''"/>
          <div v-html="wikiDic.content" style="margin-top:50px"></div>
        </div>
        <div v-show="isUpdate">
          <a-col>
            <a-form :form="form" :wrapper-col="{ span: 24 }" style="position:relative">
              <a-form-item style="position:absolute;top:50px;">
                <j-editor style="height: auto" v-decorator="['content', {initialValue:wikiDic.content}]" triggerChange>
                </j-editor>
              </a-form-item>
            </a-form>
            <a-form-item :wrapperCol="{ span: 8 }" style="">
              <a-button type="primary" style="" @click="saveWikiDicContent()">保存</a-button>
            </a-form-item>
          </a-col>
        </div>
      </div>
    </a-col>
  </a-row>

</template>

<script>
import JEditor from '@/components/Editor/JEditor'
import { detailKonwList,delDetailKonwList,getDetailList,updateDetailKonwList } from "@/httpsAPI/knowledgeBase/index";
import AddForm from './add'
import UpdateForm from './update'
import accessoryForm from './accessory'

const treeData = [];
const getParentKey = (title, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.title === title)) {
        parentKey = node.key;
      } else if (getParentKey(title, node.children)) {
        parentKey = getParentKey(title, node.children);
      }
    }
  }

  return parentKey;
};
const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const key = node.key;
    dataList.push({ key, title: node.title });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export default {
  data () {
    return {
      form: this.$form.createForm(this),
      showLine: true,
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: true,
      treeData,
      wikiId: '',
      wikiDic: {},
      isUpdate: false,
      selectedRows: [],
      addFormVisible: false,
      updateFormVisible: false,
      selWikiDicId: 0,
      parentId: 0,
      content:null,
      updateEvt:false,
      accessoryVisible: false,
      detailType:null,
      fileSize:''
    }
  },
  created () {
    this.wikiId = this.$route.query.wikiId
    this.detailType = this.$route.query.detailType
    this.loadTreeData()
  },
  components: {
    AddForm,
    UpdateForm,
    JEditor,
    accessoryForm,
  },
  methods: {
    //附件管理按钮
    fileManagementButton() {
        this.accessoryVisible = true;
        this.$nextTick(() => {
            this.$refs.accessoryForm.init(this.selWikiDicId)
        })
    },
    //编辑内容按钮
    updateWikiDicContent () {
      this.isUpdate = true;
    },
    //返回按钮
    returnWikiDicContent () {
      this.$router.go(-1)
    },
    //保存修改内容
    saveWikiDicContent(e) {
        this.form.validateFields((err, values) => {
            if (err) {
                console.log(err)
                return;
            }

            values['id'] = this.wikiDic.id;
            values['wikiId'] = this.wikiDic.wikiId;
            values['name'] = this.wikiDic.name;
            values['remark'] = this.wikiDic.remark;
            values['sort'] = this.wikiDic.sort;
            updateDetailKonwList(values).then(res => {
                this.$message.success({
                    content: '操作成功',
                    duration: 1,
                    onClose: () => {
                        this.form.resetFields();
                        this.loadDetailData();
                    }
                })
            }).catch(error => {
                console.log(error)
            })

        });
    },
    // 新增一级目录
    createWikiDicEvt () {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init({ wikiId: this.wikiId, parentId: this.parentId })
      })
    },
    // 新建子菜单
    createSubMenuEvt (item) {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init({ wikiId: this.wikiId, parentId: item.key })
      })
    },
    // 修改目录
    updateWikiDicEvt (item) {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateForm.init(item.key)
      })
    },
    // 删除目录
    deleteWikiDicEvt(item) {
        const that = this
        this.$confirm({
            title: '系统提示',
            content: '确认删除此菜单[' + item.title + ']?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                delDetailKonwList([item.key]).then(res => {
                    that.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            that.loadTreeData()
                        }
                    })
                }).catch(error => {
                    console.log(error)
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    },
    handleOver (item) {
      item.dataRef.isShow = true
    },
    handleOut (item) {
      item.dataRef.isShow = false
    },
    // 选择树形节点
    onSelectNode (item) {
      this.updateEvt = true
      this.selWikiDicId = item.key
      this.loadDetailData()
    },
    filterTreeData (arr) {
      arr.map(t => {
        if (t.children && t.children.length) {
          this.filterTreeData(t.children)
        }
        return this.$set(t, 'isShow', false)
      })
    },
    onSearch (e) {
      const value = e.target.value;
      const expandedKeys = dataList
        .map(item => {
          if (item.title.indexOf(value) > -1 && value != '') {
            return getParentKey(item.title, this.treeData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
    },
    onExpand (expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    loadTreeData () {
      detailKonwList({ wikiId: this.wikiId }).then(res => {
        this.treeData = res.data
        this.filterTreeData(this.treeData)
        generateList(this.treeData);

        if(this.treeData.length>0) {
          this.updateEvt = true
          this.selWikiDicId = this.treeData[0].key
          this.loadDetailData()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    loadDetailData () {
      getDetailList(this.selWikiDicId).then(res => {
        this.fileSize = res.data.fileSize
        this.form.resetFields();
        this.wikiDic = res.data;
        this.content = this.wikiDic.content;
        this.isUpdate = false;
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style lang="less">
.wrapper {
  height: 100%;
  margin: 0 !important;
}

.tree-node-title {
  display: inline-block;
  margin-right: 8px;
}

.tree-node-handle {
  .anticon {
    margin: 4px;
  }
}
</style>